import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { BigNumber } from '@ethersproject/bignumber';
import { log } from '@logtail/next';

import { BaseTokenSymbol, getBaseTokenDecimals } from '@src/contracts';
import { setModalState, updatePool } from '@src/features/staking';
import {
    useAppDispatch,
    useDecubateContract,
    useTokenPriceWei,
} from '@src/hooks';
import { useGlobalContext } from '@src/hooks/useGlobalContext';
import { useSwitchChain } from '@src/hooks/useSwitchChain';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import { ContractType, StakingModalTabs } from '@src/ts/constants';
import { StakingVersion } from '@src/ts/types';
import { executeTx, parseBalance } from '@src/utils/web3';

export const useBounty = (
    contract_idx: number,
    chain_id: number,
    pool_id: number,
    pool_type: StakingVersion,
) => {
    const is_legacy_compound = ['legacy-compound'].includes(pool_type);
    const decimals = useMemo(() => getBaseTokenDecimals(chain_id), [chain_id]);
    const token_price = useTokenPriceWei(BaseTokenSymbol, decimals);
    const { contract_manager } = useGlobalContext();
    const { account, connect } = useWeb3Onboard();
    const [bounty, setBounty] = useState(BigNumber.from(0));
    const [initial, setInitial] = useState(true);
    const [loading, setLoading] = useState(false);
    const [compounder] = useDecubateContract(
        is_legacy_compound ? ContractType.LegacyVault : ContractType.Vault,
        true,
        chain_id,
    );

    const dispatch = useAppDispatch();

    const { isConnectedChainEventChain, setChainID } = useSwitchChain();

    useEffect(() => {
        const ro_compound = contract_manager.getContract(
            is_legacy_compound ? ContractType.LegacyVault : ContractType.Vault,
            chain_id,
        );

        const update = () =>
            ro_compound.contract
                .calculateHarvestDcbRewards(contract_idx)
                .then((n: BigNumber) => setBounty(n))
                .catch((err) => log.info('error getting bounty', err));

        if (initial && !isNaN(contract_idx)) {
            setInitial(false);
            update();
        }

        const handler = setInterval(() => update, 1000);

        return () => {
            clearInterval(handler);
        };
    }, [contract_manager, chain_id, contract_idx]);

    const reward_usd = bounty.mul(token_price).div((10 ** decimals).toString());

    const handleClaimBounty = async () => {
        if (!isConnectedChainEventChain(chain_id)) {
            await setChainID(chain_id);
            return;
        }

        if (!account) {
            connect();
            return;
        }

        setLoading(true);
        try {
            const gasLimit = await compounder.estimateGas.harvest(
                contract_idx,
                {
                    gasLimit: '5000000',
                },
            );

            await executeTx(
                compounder.harvest(contract_idx, {
                    gasLimit: gasLimit.mul(12).div(10),
                }),
                `Successfully claimed bounty from pool`,
            );
            dispatch(updatePool(pool_id, pool_type, account, contract_manager));
            dispatch(setModalState(StakingModalTabs.Deposit));
        } catch (err) {
            toast.error(err.reason || err.message);
        }
        setLoading(false);
    };

    return {
        handleClaimBounty,
        loading,
        bounty,
        bounty_data: {
            text: `Claim ${BaseTokenSymbol} bounty straight to your wallet &
            compound staking rewards for everyone in
            this pool.`,
            label: `~${parseBalance(reward_usd, decimals, 3)} USD`,
            value: `${parseBalance(bounty, decimals, 6)} ${BaseTokenSymbol}`,
        },
    };
};
